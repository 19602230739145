import '@grubersjoe/slide-menu';

export default function mobileNav() {
    const menuElement = document.getElementById('mobile-navigation');
    const menu = new SlideMenu(menuElement, {
        submenuLinkAfter: '<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1000 1000" enable-background="new 0 0 1000 1000" xml:space="preserve"><g><path d="M217.6,915.1c0-19.2,7.2-38.4,21.9-53L601.5,500L239.5,137.9c-29.3-29.3-29.3-76.7,0-106c29.3-29.3,76.7-29.3,106,0L760.5,447c29.3,29.3,29.3,76.7,0,106L345.5,968c-29.3,29.3-76.7,29.3-106,0C224.8,953.4,217.6,934.2,217.6,915.1L217.6,915.1z"/></g></svg>',
    });

    document.getElementById('mobile-open').addEventListener("click", function () {
        menuElement.style.display = 'block';
        menu.open();
        menuElement.classList.add('active');
    });

    document.getElementById('close-menu').addEventListener("click", function () {
        menu.close();
        menuElement.classList.remove('active');
        menu.onTransitionEnd = menuElement.style.display = 'none';
    });

    // window.addEventListener('click', (event) => {
    //     if (document.getElementById('mobile-navigation').classList.contains('active') &&
    //         !menuElement.contains(event.target) &&
    //         !document.getElementById('mobile-open').contains(event.target)) {
    //         menu.close();
    //         menuElement.classList.remove('active');
    //     }
    // })
    // document.getElementById('mobile-navigation').addEventListener('click',(event)=>{
    //    console.log('------------------------');
    //    console.log(event.target);
    //    console.log('------------------------');
    // });

    menuElement.addEventListener('sm.forward', function () {
        let currentMenu = document.querySelectorAll('.slide-menu__submenu--active');
        currentMenu = currentMenu[currentMenu.length - 1].parentElement;
        updateActiveMobileMenuElement(currentMenu);
        calcSubmenuContainerHeight();
    })

    menuElement.addEventListener('sm.back-after', function () {
        const prevMenu = document.querySelector('.active-submenu');
        let currentMenu;
        if (prevMenu.classList.contains('menu-lvl-3')) {
            currentMenu = prevMenu.closest('.menu-lvl-2');
        } else if (prevMenu.classList.contains('menu-lvl-2')) {
            currentMenu = prevMenu.closest('.slide-menu__slider');
        }
        updateActiveMobileMenuElement(currentMenu);
        calcSubmenuContainerHeight();
    })

    menuElement.addEventListener('sm.open', function () {
        updateActiveMobileMenuElement(document.querySelector('.slide-menu__slider'));
        menu.navigateTo(document.querySelector('#mobile-menu > li'));
        document.querySelector('.site-header__nav-overlay').classList.add('active');
        document.body.style.overflow = 'hidden';
        calcSubmenuContainerHeight();
    });

    menuElement.addEventListener('sm.close', function () {
        document.querySelector('.site-header__nav-overlay').classList.remove('active');
        document.body.style.overflow = '';
    });

    [...menuElement.querySelectorAll('#mobile-menu .slide-menu__backlink')].forEach(backLink => {
        backLink.innerText = 'Wstecz';
        backLink.setAttribute('href', '#');

    })

    menuElement.style.cssText = 'left: auto; right: 0px;';

}



const updateActiveMobileMenuElement = (currentActive) => {
    if (!currentActive) return;

    const menuContainers = document.querySelectorAll('.slide-menu__slider, #mobile-navigation .sub-menu-wrapper');

    menuContainers.forEach(container => {
        if (container.classList.contains('active-submenu')) {
            if (document.querySelector('.active-submenu').classList.contains('slide-menu__slider')) container.firstChild.style.maxHeight = '';
            container.classList.remove('active-submenu');
            container.removeAttribute('style');
        }
    })
    //console.log(document.querySelector('.active-submenu'));
    currentActive.classList.add('active-submenu');
    //console.log(document.querySelector('.active-submenu'));
}

const calcSubmenuContainerHeight = () => {
    const menuElement= document.querySelector('#mobile-navigation');

    const footerHeight = document.querySelector('.mobile-navigation__footer').offsetHeight;
    const navTopPadding = parseInt(window.getComputedStyle(menuElement, null).getPropertyValue('padding-top').replace('px', ''));
    const firstElementHeight = document.querySelector('.active-submenu > ul > li:nth-of-type(1)').offsetHeight;
    const secondElementHeight = document.querySelector('.active-submenu > ul > li:nth-of-type(2)').offsetHeight;

    //const sum = footerHeight + navTopPadding + firstElementHeight + secondElementHeight;
    const sum = footerHeight + firstElementHeight + secondElementHeight;

    if (document.querySelector('.active-submenu').classList.contains('slide-menu__slider')) {
        document.querySelector('.active-submenu > ul').style.maxHeight = `calc(100vh - ${sum}px)`;
        //document.querySelector('.active-submenu').style.paddingTop = `${firstElementHeight + secondElementHeight}px`;
    } else {
        document.querySelector('.active-submenu').style.maxHeight = `calc(100vh - (${sum}px + 78px))`;
        document.querySelector('.active-submenu').style.marginTop = `${firstElementHeight + secondElementHeight}px`;
    }
}