export default function desktopNav() {
    const menuThirdLvlContainers = document.querySelectorAll('.desktop-navigation .menu-lvl-3');

    menuThirdLvlContainers.forEach(menuThirdLvl => {
    const thirdLvlElements = menuThirdLvl.querySelector('.sub-menu').children;
    let setContainerHeightTimeout, resetContainerHeightTimeout;

    menuThirdLvl.parentElement.addEventListener("mouseenter", () => {
        const menuLvl4 = menuThirdLvl.querySelector('.current-page-ancestor>.menu-lvl-4') || menuThirdLvl.querySelector('.current-menu-item>.menu-lvl-4');
        if (menuLvl4){
            menuLvl4.style.visibility = 'visible';
            menuLvl4.style.opacity = 1;
        }

        setDefaultHeight(menuThirdLvl);

    });

    [...thirdLvlElements].forEach(menuItem => {
        const menu4Lvl = menuItem.querySelector('.menu-lvl-4');

        menuItem.addEventListener('mouseenter', () => {
            clearTimeout(setContainerHeightTimeout);
            clearTimeout(resetContainerHeightTimeout);

            [...thirdLvlElements].forEach(elem => {
                const elemSubMenu = elem.querySelector('.sub-menu-wrapper');

                if (elemSubMenu){
                    elemSubMenu.style.visibility = 'hidden';
                    elemSubMenu.style.opacity = 0;
                }
            })

            resetContainerHeightTimeout = setTimeout(()=>{menuThirdLvl.querySelector('.sub-menu').style.height = '';},200);

            if (menu4Lvl) {
                menu4Lvl.style.visibility = 'visible';
                menu4Lvl.style.opacity = 1;
                setContainerHeightTimeout = setTimeout(() => setContainerHeight(menu4Lvl.querySelector('.sub-menu'), menuThirdLvl),200);
            }
        })

        menuItem.addEventListener('mouseleave', () => {
            clearTimeout(setContainerHeightTimeout);
            clearTimeout(resetContainerHeightTimeout);

            [...thirdLvlElements].forEach(elem => {
                const elemSubMenu = elem.querySelector('.sub-menu-wrapper');

                if (elemSubMenu && !elem.classList.contains('current-page-ancestor')) {
                    elemSubMenu.style.visibility = '';
                    elemSubMenu.style.opacity = '';
                }

                if(elem.classList.contains('current-page-ancestor')) {
                    let active3lvlElem = menuThirdLvl.querySelector('.current-page-ancestor') || menuThirdLvl.querySelector('.current-menu-item');
                    active3lvlElem.querySelector('.menu-lvl-4').style.visibility = 'visible';
                    active3lvlElem.querySelector('.menu-lvl-4').style.opacity = 1;
                }
            });

            resetContainerHeightTimeout = setTimeout(()=>{setDefaultHeight(menuThirdLvl);},200);

        })

    })

    menuThirdLvl.addEventListener("mouseleave", () => {
        const menuLvl4 = menuThirdLvl.querySelector('.current-page-ancestor>.menu-lvl-4') || menuThirdLvl.querySelector('.current-menu-item>.menu-lvl-4');
        if (menuLvl4){
            menuLvl4.style.visibility = '';
            menuLvl4.style.opacity = '';
        }

        setDefaultHeight(menuThirdLvl);

    })
})

    menuOverlay();

    keepSearchInputOpen();

    menuAddClassBootstrap();
}
const menuFirstLvl = document.querySelectorAll('ul.menu > li.menu-item');

menuFirstLvl.forEach(menuItem => {
    if (menuItem.classList.contains('bootstrap')) {

    }
})

const setContainerHeight = (subMenu, container) => {
    const innerContainerHeight = subMenu.offsetHeight + 32;
    container.querySelector('.sub-menu').style.height = '';
    let bootstrapMultiParent = subMenu.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement;

    if(!bootstrapMultiParent.classList.contains('bootstrap')){
        if (innerContainerHeight > container.offsetHeight) {
            container.querySelector('.sub-menu').style.height = innerContainerHeight + "px";
        }
    }else{

    }
}

const setDefaultHeight = (menuThirdLvl) => {
    let active3lvlElem = menuThirdLvl.querySelector('.current-page-ancestor') || menuThirdLvl.querySelector('.current-menu-item');

    menuThirdLvl.querySelector('.sub-menu').style.height = '';

    if (active3lvlElem && active3lvlElem.querySelector('.sub-menu')) {
        setContainerHeight(active3lvlElem.querySelector('.sub-menu'), menuThirdLvl);
    }
}

const menuOverlay = () => {
    const desktopNav = document.querySelector('.desktop-navigation');
    const menuSecondLvl = desktopNav.querySelectorAll('.menu-lvl-2 > .sub-menu > li, .skip-second-menu-lvl, .skip-second-menu-lvl.menu-external-icon');

    menuSecondLvl.forEach(menuItem => {
        if (!menuItem.classList.contains('menu-external-icon')){
            if (menuItem.classList.contains('menu-item-has-children')) {
                menuItem.addEventListener('mouseenter', () => {
                    document.querySelector('.site-header__nav-overlay').classList.add('active');
                })

                menuItem.addEventListener('mouseleave', () => {
                    const ancestorWithSkipMenuLvl = menuItem.closest('li.skip-second-menu-lvl');

                    if (!ancestorWithSkipMenuLvl || ancestorWithSkipMenuLvl.id === menuItem.id) {
                        document.querySelector('.site-header__nav-overlay').classList.remove('active');
                    }
                })
            }
        }
    })
}

const keepSearchInputOpen = () => {
    const menuSearchInputWrapper = document.querySelector('.search-form__input-wrapper');

    // Move label based on field value
    const fieldValueToggleLabels = (input) => {
        if (input?.value !== '') {
            menuSearchInputWrapper?.classList.toggle('has-value');
        }
    }

    const onEventToggleLabels = (input) => {
        ['focus', 'blur'].forEach(event => {
            input?.addEventListener(event, (e) => {
               if(event === 'focus'){
                   menuSearchInputWrapper?.classList.add('has-value');
               }else if(event === 'blur') {
                   if(!e.target.value) menuSearchInputWrapper?.classList.remove('has-value');
               }
            });
        });
    }

    if (menuSearchInputWrapper) {
        const menuSearchInput = menuSearchInputWrapper.querySelector('input');

        fieldValueToggleLabels(menuSearchInput);
        onEventToggleLabels(menuSearchInput);
    }
}

const menuAddClassBootstrap = () => {
    const menuBottomMenuBar = document.querySelector('.bottom-menu-bar');
    const menuFirstLvl = document.querySelectorAll('ul.menu > li.menu-item');
    const currentMenuItem = document.querySelector('ul.menu .current-menu-item .menu-lvl-2, ul.menu .current-page-ancestor .menu-lvl-2');

    menuFirstLvl.forEach(menuItem => {
        if (menuItem.classList.contains('bootstrap')) {

            menuItem.addEventListener('mouseenter', (e) => {
                menuBottomMenuBar.classList.add('bootstrap');
                if(!e.target.classList.contains('bootstrap') && !e.target.classList.contains('current_page_item')) {
                    currentMenuItem.classList.add('d-none');
                }else if(e.target.classList.contains('bootstrap') && !e.target.classList.contains('current_page_item')){
                    currentMenuItem.classList.add('d-none');
                }else{
                }
            });

            menuItem.addEventListener('mouseleave', (e) => {
                const ancestorWithSkipMenuLvl = menuItem.closest('li.skip-second-menu-lvl');
                if (!ancestorWithSkipMenuLvl || ancestorWithSkipMenuLvl.id === menuItem.id) {
                    menuBottomMenuBar.classList.remove('bootstrap');
                    if(e.target) currentMenuItem.classList.remove('d-none');
                }
            });
        }
    })
}